import { DocumentType, StockType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { PageProps } from 'gatsby';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CMSView from '~/components/View';
import EspaceItem from '~/containers/Espace/Item';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageProduitsStock: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, produitId },
}) => {
  const [stocks, setStocks] = useState<DocumentType<StockType>[]>();

  useEffect(() => {
    let isMounted = true;
    if (produitId) {
      const produitData = new ProduitData({
        espaceId,
      });

      produitData
        .findStockById(produitId)
        .then(docs => {
          if (isMounted) {
            setStocks(docs);
          }

          return isMounted;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [produitId]);

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <EspaceItem>
          {stocks &&
            stocks.map(stock => (
              <div key={stock.id}>
                {dayjs(stock.createdAt).format('dddd D MMMM YYYY HH:mm')}{' '}
                {stock.quantity} {stock.userId}
              </div>
            ))}
        </EspaceItem>
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageProduitsStock);
